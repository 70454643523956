import { defineStore } from "pinia";
import { useNuxtApp, showError } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";

export const useAgentsStore = defineStore("agents", {
	state: () => ({
		agents: [],
		allAgents: [],
	}),
	actions: {
		async fetchFilteredAgents() {
			const { api } = useNuxtApp();
			const thisStore = useAgentsStore();

			const { error } = await $useFetch(api.agents, {
				method: "GET",
				onResponse({ response }) {
					if (response.status === 200) {
						thisStore.agents = response._data.data;
					}
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},
		async fetchAllAgents() {
			const { api } = useNuxtApp();
			const thisStore = useAgentsStore();

			const { error } = await $useFetch(`${api.agents}?all=1`, {
				method: "GET",
				onResponse({ response }) {
					if (response.status === 200) {
						thisStore.allAgents = response._data.data;
					}
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},
		async fetchAgents() {
			await this.fetchAllAgents();
			await this.fetchFilteredAgents();
		},
	},
	getters: {
		getAgents() {
			return this.agents;
		},
		getAllAgents() {
			return this.allAgents;
		},
		getAgentsNamed() {
			const agentsByName = [];

			for (const item in this.agents) {
				agentsByName.push(this.agents[item].name);
			}
			return agentsByName;
		},
		getAgentsIdByName: (state) => (name) => {
			for (const item in state.agents) {
				if (state.agents[item].name === name) return state.agents[item].id;
			}
		},
		getAgentsNameById: (state) => (id) => state.agents.find((val) => val.id === id)?.name,
		getAllAgentsNameById: (state) => (id) => state.allAgents.find((val) => val.id === id)?.name,
		getAgentsObjectById: (state) => (array) => state.allAgents.filter((val) => array.includes(val.id)),
	},
});
